import {createRouter, createWebHistory} from 'vue-router'
 
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: () => import('@/views/home')
    },
    {
      path: '/login',
      // meta: { title: '登录', noCache: true },
      component: () => import('@/views/employee/login')
    },
    {
      path: '/inventory',
      component: () => import('@/views/employee/inventory')
    },
    {
      path: '/scan',
      component: () => import('@/views/employee/scan')
    },
    {
      path: '/pointInfo',
      component: () => import('@/views/employee/pointInfo')
    },
    {
      path: '/pay',
      component: () => import('@/views/pay')
    },
  ]
})

export default router
