// import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import VueCookies from 'vue-cookies'
// import store from './store/index'

import { createApp } from 'vue'
import { Button, Card, Toast, Stepper, SubmitBar, Overlay, Form, Field, CellGroup, Icon, Image, Picker, Popup, NavBar, Cascader, Col, Row } from 'vant'

const _app = createApp(App)

//路由
_app.use(router)
//cookies缓存
_app.use(VueCookies)
//vuex状态管理
// _app.use(store)

//vant组件
_app.use(Button)
_app.use(Card)
_app.use(Toast)
_app.use(Stepper)
_app.use(SubmitBar)
_app.use(Overlay)
_app.use(Form)
_app.use(Field)
_app.use(CellGroup)
_app.use(Icon)
_app.use(Image)
_app.use(Picker)
_app.use(Popup)
_app.use(NavBar)
_app.use(Cascader)
_app.use(Col)
_app.use(Row)

_app.mount('#app')
